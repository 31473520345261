import styled, { css } from 'styled-components'
import { FormattedMessage } from 'react-intl'
import Container from './layout/Container'
import { ConditionalLink } from './Link'
import Image from './Image'
import ArrowIcon from './icons/ArrowIcon'

function TheSites( { sites, priority = false } ) {
  return (
    <Container>
      <StyledHeading>
        <FormattedMessage
          description="The Artworks"
          defaultMessage="The Artworks"
          id="XT/4YO"
        />
      </StyledHeading>
      <StyledTheSites>
        { sites.map( site => (
          <ConditionalLink 
            Component="div"
            key={ site.id }
            href={ site.artworkUrl }
            passHref
          >
            <StyledSiteImage>
              <Image
                src={ site.siteImage[ 0 ].url }
                alt={ site.title }
                layout="fill"
                objectFit="cover"
                priority={ priority }
              />
              { site.artworkUrl && (
                <StyledArrow>
                  <ArrowIcon color="grey1" />
                </StyledArrow>
              ) }
              { site.artworkTag && (
                <StyledTag>
                  { site.artworkTag }
                </StyledTag>
              ) }
              { site.siteImage[ 0 ].attribution && (
                <StyledAttribution $position={ site.artworkTag ? 'bottom' : 'top' }>
                  { site.siteImage[ 0 ].attribution }
                </StyledAttribution>
              ) }
            </StyledSiteImage>
            <StyledSiteInfo>
              <h3>
                { site.artworkTitle }
                <span>{ site.artistName }</span>
              </h3>
              <p>{ site.title }</p>
            </StyledSiteInfo>
          </ConditionalLink>
        ) ) }
      </StyledTheSites>
    </Container>
  )
}

const StyledHeading = styled.h2`
  margin-top: 0;
  margin-bottom: 2rem;
  ${ p => p.theme.typo.neueHaas };
  font-size: ${ p => p.theme.typo.sizes.medium };
  color: ${ p => p.theme.colors.grey9 };
`

const StyledSiteImage = styled.div`
  position: relative;
  padding-bottom: 100%;

  img {
    opacity: 0.7;
    transition: opacity .3s ease-out;
  }
`

const StyledArrow = styled.span`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${ p => p.theme.colors.grey9 };
`

const StyledTag = styled.span`
  position: absolute;
  top: 1rem;
  left: 1rem;
  font-size: ${ p => p.theme.typo.sizes.large };
  color: ${ p => p.theme.colors.grey9 };
`

const StyledAttribution = styled.span<{ $position: 'top' | 'bottom' }>`
  position: absolute;
  font-size: ${ p => p.theme.typo.sizes.dropped };
  color: ${ p => p.theme.colors.grey9 };

  ${ p => p.$position === 'top' && css`
    top: 1rem;
    left: 1rem;
  ` }
  
  ${ p => p.$position === 'bottom' && css`
    bottom: 1rem;
    right: 1rem;
  ` }
`

const StyledSiteInfo = styled.div`
  margin-top: 1rem;
  
  h3 {
    margin: 0;
    ${ p => p.theme.typo.neueHaasBold }
    font-size: ${ p => p.theme.typo.sizes.large };
    color: ${ p => p.theme.colors.grey8 };
    transition: color .3s ease-out;

    span {
      display: block;
      ${ p => p.theme.typo.neueHaas }
    }
  }

  p {
    margin-top: 1rem;
    font-size: ${ p => p.theme.typo.sizes.medium };
    color: ${ p => p.theme.colors.grey7 };
  }
`

const StyledTheSites = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: var( --grid-parent-gutter );

  > * {
    box-sizing: border-box;
    margin: var( --grid-child-gutter );
    flex: 1 0 calc( 33.333% - 2rem );
    min-width: 250px;
  }

  > a,
  > div {
    position: relative;
    display: block;
    color: inherit;
  }

  > a {
    text-decoration: none;

    &:hover {
      ${ StyledSiteImage } img {
        opacity: 1;
      }

      ${ StyledSiteInfo } h3 {
        color: ${ p => p.theme.colors.grey9 };
      }
    }
  }
`

export default TheSites
