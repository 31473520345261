import { useEffect } from 'react'

const useWindowResize = ( handler = null, deps = [], onLoad = false ) => {
  useEffect( () => {
    if ( typeof window !== 'undefined' ) {
      window.addEventListener( 'resize', handler )

      if ( onLoad ) {
        handler()
      }

      return () => window.removeEventListener( 'resize', handler )
    }
  }, deps )
}

export default useWindowResize
