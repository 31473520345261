function Video( { contentItem } ) {
  const { videoType, videoUrl } = getVideoParams( contentItem )

  if ( videoType === 'file' ) {
    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video controls>
        <source src={ videoUrl } />
        Sorry, your browser doesn’t support embedded videos.
      </video>
    )
  }

  if ( videoType === 'embed' ) {
    return (
      <iframe
        width="853"
        height="480"
        src={ videoUrl }
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    )
  }

  return null
}

function getVideoParams( block ) {
  if ( block.videoFile?.[ 0 ]?.url ) {
    return {
      videoType: 'file',
      videoUrl: block.videoFile?.[ 0 ]?.url,
    }
  }

  if ( block.videoUrl ) {
    return {
      videoType: 'embed',
      videoUrl: getVideoUrl( block.videoUrl ),
    }
  }

  return {
    videoType: null,
    videoUrl: null,
  }
}

function getVideoUrl( url: string ) {
  const youTubeRegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const youTubeMatch = url.match( youTubeRegExp )

  if ( youTubeMatch && youTubeMatch[ 2 ]?.length === 11 ) {
    return `//www.youtube-nocookie.com/embed/${ youTubeMatch[ 2 ] }`
  }

  const vimeoRegExp = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_-]+)?/i
  const vimeoMatch = url.match( vimeoRegExp )

  if ( vimeoMatch && vimeoMatch[ 1 ]?.length > 1 ) {
    return `//player.vimeo.com/video/${ vimeoMatch[ 1 ] }?dnt=1`
  }
  
  return null
}

export default Video
