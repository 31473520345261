import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import useWindowResize from '../hooks/useWindowResize'
import Image from './Image'
import Chevron from './icons/Chevron'

function Splash( { image = null, video = null, scrollTo = null } ) {
  const playerRef = useRef( null )
  const [isPortrait, setIsPortrait] = useState( false )
  const videos = video && getVideos( video, isPortrait )

  useWindowResize( () => {
    if ( !isPortrait && window.innerWidth / window.innerHeight < 1 ) {
      setIsPortrait( true )
    }
    else if ( isPortrait && window.innerWidth / window.innerHeight >= 1 ) {
      setIsPortrait( false )
    }
  }, [], true )

  const onScrollBtnClick = e => {
    e.preventDefault()
    
    if ( scrollTo?.current ) {
      scrollTo.current.scrollIntoView( { behavior: 'smooth', block: 'start' } )
    }
  }

  useEffect( () => {
    const player = playerRef.current

    const onVideoEnd = () => {
      if ( scrollTo?.current && window.scrollY === 0 ) {
        scrollTo.current.scrollIntoView( { behavior: 'smooth', block: 'start' } )
      }
    }

    player?.addEventListener( 'ended', onVideoEnd )
    
    return () => {
      player?.addEventListener( 'ended', onVideoEnd )
    }
  }, [] )

  return (
    <StyledSplash>
      <StyledImageWrapper>
        { image && (
        <Image
          src={ image.url }
          alt=""
          layout="fill"
          objectFit="cover"
        />
        ) }
        { video && (
          <video 
            ref={ playerRef }
            key={ JSON.stringify( videos ) }
            autoPlay 
            muted 
            playsInline
          >
            { videos.map( ( { type, src } ) => (
              <source 
                key={ type }
                src={ src } 
                type={ type }
              />
            ) ) }
          </video>
        ) }
      </StyledImageWrapper>
      <StyledScrollBtn onClick={ onScrollBtnClick }>
        <Chevron 
          color="black"
          down 
        />
      </StyledScrollBtn>
    </StyledSplash>
  )
}

const getVideos = ( videos, isPortrait ) => {
  if ( isPortrait ) {
    const vids = videos.filter( v => v.portrait )

    if ( vids.length ) {
      return videos.filter( v => v.portrait )
    }
  }

  const vids = videos.filter( v => !v.portrait )

  if ( vids.length ) {
    return videos.filter( v => !v.portrait )
  }

  return videos
}

const StyledSplash = styled.div`
  position: relative;
  height: 100vh;

  `
const StyledImageWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;

  video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const StyledScrollBtn = styled.div`
  position: absolute;
  bottom: 5vh;
  left: 50%;
  transform: translateX( -50% );
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: ${ p => p.theme.colors.highlight };
  cursor: pointer;

  svg {
    margin: 0.2rem auto 0;
    width: 32%;
  }
`

export default Splash
