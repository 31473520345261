import { useEffect, useRef } from 'react'
import Router from 'next/router'
import Cookies from 'js-cookie'
import Background from '../components/layout/Background'
import Stack from '../components/layout/Stack'
import AppChrome from '../components/AppChrome'
import Splash from '../components/Splash'
import Hero from '../components/Hero'
import TheSites from '../components/TheSites'
import ContentBuilder from '../components/ContentBuilder'
import LetsDiscusCTABlock from '../components/LetsDiscusCTABlock'
import MetaTags from '../components/MetaTags'

export { getServerSideProps } from '../graphql/homepage'

function Home( { entry: homepage, sites } ) {
  const heroRef = useRef( null )
  const heroContent = homepage.hero[ 0 ]
  
  useEffect( () => {
    if ( Cookies.get( 'hod-viewed-splash' ) ) {
      heroRef.current.scrollIntoView( { block: 'start' } )
    }

    const setSplashViewedCookie = () => {
      Cookies.set( 'hod-viewed-splash', true )
    }

    Router.events.on( 'routeChangeStart', setSplashViewedCookie )
    window.addEventListener( 'beforeunload', setSplashViewedCookie )

    return () => {
      Router.events.off( 'routeChangeStart', setSplashViewedCookie )
      window.removeEventListener( 'beforeunload', setSplashViewedCookie )
    }
  }, [] )

  return (
    <AppChrome pageHasSplash darkPage>
      <MetaTags
        url="/"
        title={ null }
      />
      <Splash
        video={ [
          {
            portrait: true,
            type: 'video/mp4',
            src: 'https://cdn.housesofdarkness.eu/videos/HOD_Trailer_Mobile.mp4',
          },
          {
            portrait: false,
            type: 'video/mp4',
            src: 'https://cdn.housesofdarkness.eu/videos/HOD_Trailer_V5.mp4',
          },
        ] }
        scrollTo={ heroRef }
      />
      <Background 
        $gradient={ {
          angle: 180,
          steps: {
            11: 'grey2',
            53: 'grey3',
            100: 'grey5',
          },
        } }
      >
        <Hero 
          elementRef={ heroRef }
          title={ heroContent.heroTitle }
          subheading={ heroContent.subtitle }
          html={ heroContent.body }
          light
        />
        <Stack
          $top="3rem"
          $bottom="3rem"
        >
          <TheSites sites={ sites } priority />
        </Stack>
        <Stack
          $top="3rem"
          $bottom="3rem"
        >
          <ContentBuilder 
            content={ homepage.contentBuilder } 
            light
          />
        </Stack>
        <LetsDiscusCTABlock />
      </Background>
    </AppChrome>
  )
}

export default Home
