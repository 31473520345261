import { FormattedMessage } from 'react-intl'
import CTABlock from './CTABlock'
import Button from './Button'
import Link from './Link'

function LetsDiscusCTABlock() {
  return (
    <CTABlock>
      <h3>
        <FormattedMessage
          description="Has this project made you think?"
          defaultMessage="Has this project made you think?"
          id="Q6KiQZ"
        />
      </h3>
      <Button
        $rounded
        $color="grey9"
      >
        <Link href="/lets-discuss">
          Let’s discuss
        </Link>
      </Button>
      <Link href="/lets-explore">
        <FormattedMessage
          description="More information"
          defaultMessage="More information"
          id="aGuUOT"
        />
      </Link>
    </CTABlock>
  )
}

export default LetsDiscusCTABlock
