import styled from 'styled-components'
import type { ColorKeys } from '../../styles/colors'

function ArrowIcon( { color = 'grey1' as ColorKeys } ) {
  return (
    <StyledArrowIcon $color={ color }>
      <svg 
        width="22"
        height="20"
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path 
          d="M10.7517 0.959473L19.772 9.97977L10.7517 19.0001"
          stroke="#0F0F0F"
          strokeWidth="2"
        />
        <path 
          d="M19.4924 10.0358L-5.38422e-08 10.0358"
          stroke="#0F0F0F"
          strokeWidth="2"
        />
      </svg>
    </StyledArrowIcon>
  )
}

const StyledArrowIcon = styled.div<{ $color: ColorKeys }>`
  svg {
    display: block;
    
    path {
      stroke: ${ p => p.theme.getColor( p.$color ) };
    }
  }
`

export default ArrowIcon
