import styled from 'styled-components'
import Container, { ShiftAlign } from './layout/Container'
import CmsTextBlock from './CmsTextBlock'
import ImagesBlock from './ImagesBlock'
import Video from './Video'
import Button from './Button'
import Link from './Link'

function ContentBuilder( { content, light = false } ) {
  const color = light ? 'grey9' : 'grey2'

  return (
    <StyledContentStack>
      { content.map( contentBlock => {
        if ( contentBlock.typeHandle === 'text' ) {
          return (
            <div 
              key={ contentBlock.id }
              className={ `hod-text-block hod-text-block--${ contentBlock.style }` }
            >
              <CmsTextBlock 
                color={ color }
                textStyle={ contentBlock.style || 'normal' }
              >
                { contentBlock.text }
              </CmsTextBlock>
            </div>
          )
        }

        if ( contentBlock.typeHandle === 'images' ) {
          return (
            <Container 
              key={ contentBlock.id }
              className="hod-images-block"
            >
              <StyledImagesBlock>
                <ImagesBlock 
                  images={ contentBlock.images } 
                  layout="auto"
                />
              </StyledImagesBlock>
            </Container>
          )
        }
        
        if ( contentBlock.typeHandle === 'video' ) {
          return (
            <Container 
              key={ contentBlock.id }
              className="hod-video-block"
            >
              <div>
                <Video contentItem={ contentBlock } />
              </div>
            </Container>
          )
        }
        
        if ( contentBlock.typeHandle === 'button' ) {
          return (
            <Container 
              key={ contentBlock.id }
              className="hod-button-block"
            >
              <ShiftAlign>
                <Button
                  $inline
                  $color={ color }
                  $textColor={ light ? 'grey2' : 'grey9' }
                >
                  <Link href={ contentBlock.buttonLink }>
                    { contentBlock.buttonText }
                  </Link>
                </Button>
              </ShiftAlign>
            </Container>
          )
        }

        return null
      } ) }
    </StyledContentStack>
  )
}

const StyledContentStack = styled.div`
  --para-margin: 1rem;
  --button-margin: 3rem;
  --media-margin: 4rem;

  .hod-text-block {
    &:not( :first-child ) {
      margin-top: var( --para-margin );
    }
    
    &:not( :last-child ) {
      margin-bottom: var( --para-margin );
    }
  }
  
  .hod-text-block--large {
    &:not( :first-child ) {
      margin-top: var( --button-margin );
    }
    
    &:not( :last-child ) {
      margin-bottom: var( --button-margin );
    }
  }
  
  .hod-button-block {
    &:not( :first-child ) {
      margin-top: var( --button-margin );
    }
    
    &:not( :last-child ) {
      margin-bottom: var( --button-margin );
    }
  }

  .hod-images-block,
  .hod-video-block {
    &:not( :first-child ) {
      margin-top: var( --media-margin );
    }
    
    &:not( :last-child ) {
      margin-bottom: var( --media-margin );
    }
  }

  .hod-video-block {
    > div {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-bottom: 56.25%;

      > iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
      }
    }
  }
`

const StyledImagesBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: var( --grid-parent-gutter );

  > * {
    box-sizing: border-box;
    margin: var( --grid-child-gutter );
    flex: 1 0 calc( 33.333% - 2rem );
    min-width: 200px;
  }
`

export default ContentBuilder
