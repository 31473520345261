import styled from 'styled-components'
import Container, { ShiftAlign } from './layout/Container'
import { soloTextLink } from '../styles/textLink'

function TextBlock( {
  color = 'grey2', 
  textStyle,
  children, 
} ) {
  return (
    <Container>
      <ShiftAlign>
        <StyledTextBlock 
          $color={ color }
          $size={ textStyle }
          dangerouslySetInnerHTML={ { __html: children } }
        />
      </ShiftAlign>
    </Container>
  )
}

export const StyledTextBlock = styled.div<{ 
  $color: string,
  $size: string,
}>`
  color: ${ p => p.theme.getColor( p.$color ) };
  font-size: ${ p => p.$size === 'large' ? p.theme.typo.sizes.large : p.theme.typo.sizes.medium };

  > * {
    margin: 0 0 1rem 0;
    max-width: 45rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1 {
    ${ p => p.theme.typo.neueHaasBold }
    font-size: ${ p => p.theme.typo.sizes.medium };
  }
  
  h2 {
    ${ p => p.theme.typo.neueHaasBold }
    font-size: ${ p => p.theme.typo.sizes.normal };
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  h3 {
    ${ p => p.theme.typo.neueHaasBold }
    font-size: ${ p => p.theme.typo.sizes.normal };
  }

  a {
    color: inherit;
    text-decoration-color: ${ p => p.theme.colors.highlight };
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
  }

  > a,
  p a:only-child {
    ${ soloTextLink }
  }
`

export default TextBlock
