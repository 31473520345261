import styled, { css } from 'styled-components'

function Chevron( {
  color = 'white', left = false, right = true, up = false, down = false, 
} ) {
  return (
    <StyledChevron
      $left={ left }
      $right={ right }
      $up={ up }
      $down={ down }
      $color={ color }
    >
      <svg 
        width="28"
        height="48"
        viewBox="0 0 28 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path 
          d="M1.30176 47L26.4538 24.748C26.5595 24.6542 26.6441 24.539 26.7021 24.4101C26.7601 24.2811 26.7901 24.1414 26.7901 24C26.7901 23.8586 26.7601 23.7189 26.7021 23.5899C26.6441 23.461 26.5595 23.3458 26.4538 23.252L1.30176 1"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </StyledChevron>
  )
}

interface StyledChevron {
  $left?: boolean,
  $right?: boolean,
  $up?: boolean,
  $down?: boolean,
  $color?: string,
}

const StyledChevron = styled.div<StyledChevron>`
  svg {
    display: block;

    ${ p => p.$left && css`
      transform: rotate( 180deg );
    ` }
    
    ${ p => p.$up && css`
      transform: rotate( 270deg );
    ` }
    
    ${ p => p.$down && css`
      transform: rotate( 90deg );
    ` }

    path {
      stroke: ${ p => p.theme.getColor( p.$color ) };
    }
  }
`

export default Chevron
