import styled from 'styled-components'
import Container from './layout/Container'
import Stack from './layout/Stack'
import Button from './Button'
import { soloTextLink } from '../styles/textLink'

function CTABlock( { children } ) {
  return (
    <StyledCTABlockWrapper>
      <Container>
        <StyledCTABlock>
          <Stack
            $top={ 10 }
            $between={ 2 }
            $bottom={ 10 }
          >
            { children }
          </Stack>
        </StyledCTABlock>
      </Container>
    </StyledCTABlockWrapper>
  )
}

const StyledCTABlockWrapper = styled.div`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 55.03%;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${ p => p.theme.colors.grey9 };
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      180deg, 
      rgba(144, 62, 255, 0) 4.25%, 
      rgba(144, 62, 255, 0.4) 20.66%, 
      rgba(144, 62, 255, 0.8) 34.72%, 
      #903EFF 53.47%, 
      rgba(144, 62, 255, 0.8) 71.18%, 
      rgba(144, 62, 255, 0.4) 87.85%, 
      rgba(144, 62, 255, 0) 100%
    );
  }
`

const StyledCTABlock = styled.div`
  text-align: center;
  color: ${ p => p.theme.colors.grey9 };

  > * {
    position: relative;
    z-index: 1;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${ p => p.theme.typo.neueHaasBold }
    font-size: ${ p => p.theme.typo.sizes.bigCTA };
  }

  ${ Button } {
    display: flex;
    justify-content: center;

    button,
    a {
      ${ p => p.theme.typo.neueHaasBold };
      font-size: ${ p => p.theme.typo.sizes.large };
      color: ${ p => p.theme.colors.highlight };

      &:hover {
        color: ${ p => p.theme.colors.grey9 };
      }
    }
  }
  
  > div > a {
    ${ soloTextLink }
  }
`

export default CTABlock
