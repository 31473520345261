import styled, { css } from 'styled-components'
import Image from './Image'

type Layout = 
  'fill' 
  | 'fixed' 
  | 'intrinsic' 
  | 'responsive' 
  | 'auto'

type ImgLayout = 
  'fill' 
  | 'fixed' 
  | 'intrinsic' 
  | 'responsive' 

type ObjectFit = 
  'contain' 
  | 'cover' 
  | 'fill' 
  | 'none' 
  | 'scale-down' 
  | 'inherit' 
  | 'initial' 
  | 'revert' 
  | 'unset'

function ImagesBlock( { 
  images, 
  layout = 'fill' as Layout,
  objectFit = 'cover' as ObjectFit, 
} ) {
  const imgLayout = layout === 'auto'
    ? ( images.length > 1 ? 'fill' : 'intrinsic' ) as ImgLayout
    : layout as ImgLayout

  return (
    <>
      { images.map( image => (
        <StyledImage 
          key={ image.id }
          $layout={ imgLayout }
        >
          <div>
            <div>
              { imgLayout === 'fill' && (
                <Image
                  src={ image.url }
                  alt={ image.altText || image.title }
                  layout="fill"
                  objectFit={ objectFit }
                  dateModified={ image.dateModified }
                />
              ) }
              { imgLayout !== 'fill' && (
                <Image
                  src={ image.url }
                  alt={ image.altText || image.title }
                  width={ image.width }
                  height={ image.height }
                  layout={ imgLayout }
                  objectFit={ objectFit }
                  dateModified={ image.dateModified }
                />
              ) }
              { image.attribution && (
                <StyledAttribution $position="bottom">
                  { image.attribution }
                </StyledAttribution>
              ) }
            </div>
          </div>
          { image.altText && (
            <StyledDescription>
              { image.altText }
            </StyledDescription>
          ) }
        </StyledImage>
      ) ) }
    </>
  )
}

const StyledImage = styled.div<{ $layout: Layout }>`
  ${ p => p.$layout === 'fill' && css`
    > div {
      width: 100%;
      display: flex;
      align-items: center;
      
      > div {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 100%;
      }
    }
  ` }
`

const StyledAttribution = styled.span<{ $position: 'top' | 'bottom' }>`
  position: absolute;
  font-size: ${ p => p.theme.typo.sizes.dropped };
  color: ${ p => p.theme.colors.grey9 };

  ${ p => p.$position === 'top' && css`
    top: 0.6rem;
    left: 0.6rem;
  ` }
  
  ${ p => p.$position === 'bottom' && css`
    bottom: 0.6rem;
    right: 0.6rem;
  ` }
`

const StyledDescription = styled.p`
  margin-bottom: 0;
  font-size: ${ p => p.theme.typo.sizes.dropped };
  color: ${ p => p.theme.colors.grey8 };
`

export default ImagesBlock
